<template>
  <div style="max-width: 500px" class="mt-15 pt-15 px-3 ma-auto voucher-redeem">
    <h2 class="pb-5 text-center text-capitalize secondary--text">
      {{ `${$t('label.brandName')} ${$t('label.verifyVoucher')} ` }}
    </h2>
    <div class="py-10 secondary rounded white--text">
      <div class="text-center pb-5">
        <img src="/assets/image/logo.png" alt="logo" width="200px" />
        <div style="font-size: 12px" class="text-uppercase">
          {{ $t('label.slogan') }}
        </div>
      </div>
      <div class="infoborder">
        <div class="px-8" v-for="(i, index) in voucherDetailInfo" :key="index">
          <v-row no-gutters class="pb-5 align-center text-capitalize" :style="i.style" :class="i.class" :color="i.color"
            v-if="!i.isHidden">
            <v-col cols="5">{{ $t(`label.${i.name}`) }}</v-col>
            <v-col cols="7" class="font-weight-bold text-right">
              <div>
                {{ voucherDetailObj[i.key] }}
              </div>
              <div v-if="i.key == 'merchantName' &&
                voucherDetailObj.voucherUseType == VoucherUseType.MERCHANT
                ">
                {{ voucherDetailObj.branchName }}
              </div>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="!voucherDetailObj.countdownTime">
        <div v-if="voucherDetailObj.status !== 2" class="px-10 text--white font-weight-bold text-center">
          <div class="ma-auto" v-if="voucherDetailObj.voucherUseType == VoucherUseType.REFERRAL">
            <div class="pt-5 pb-3 text-uppercase">
              {{ $t('label.branchName') }}
            </div>
            <div v-if="this.$route.query.branch_name">
              <input v-model="branchName" :placeholder="this.$route.query.branch_name" class="inputPin branchName"
                disabled type="text" />
              <div v-if="erroMsg" class="red--text">
                {{ erroMsg }}
              </div>
            </div>
            <div v-else>
              <input v-model="branchName" class="inputPin branchName" type="text" />
              <div>This field is required.</div>
            </div>
          </div>
          <div class="pt-5 pb-3 text-uppercase">{{ $t('label.enterPin') }}</div>
          <div class="ma-auto">
            <CodeInput :loading="false" type="number" class="inputPin ma-auto" v-on:complete="onKeyPinComplete"
              :fields="4" :autoFocus="true" ref="code" />
          </div>
          <div class="red--text">
            {{ erroMsg2 }}
          </div>
          <div class="pt-5">
            *Merchant key in PIN code to verify voucher redemption
          </div>
        </div>
        <div v-else class="px-8 text--white font-weight-bold text-center">
          <div class="pt-5 text-center">
            <div v-if="voucherDetailObj.voucherUseType == VoucherUseType.MERCHANT"
              class="d-flex justify-space-between align-center pb-5 yellow--text">
              <div>Received Payment</div>
              <div>{{ voucherDetailObj.receivedPayment }}</div>
            </div>
            <div>*Voucher redemption completed</div>
            <div>{{ voucherDetailObj.redemptionAt }}</div>
          </div>
        </div>
      </div>
      <div v-else class="text-center pt-7" style="font-size: 20px; font-weight: bold">
        Try again in {{ countDown }} seconds
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import {
  USER_VOUCHER_GET_USER_VOUCHER,
  USER_VOUCHER_UPDATE_CHECK_USER_VOUCHER,
  USER_VOUCHER_INITIAL_GET_USER_VOUCHER_STATE,
  USER_VOUCHER_INITIAL_UPDATE_CHECK_USER_VOUCHER_STATE
} from '@/core/store/user-voucher.module';
import common from '@/core/mixin/common.mixin';
import { i18nHelper } from '@/core/utils';
import CodeInput from 'vue-verification-code-input';
import { Voucher_Use_Type } from '@/core/constants/enums';

export default {
  name: 'VerifyRedeem',
  mixins: [common],
  components: {
    CodeInput
  },
  data: () => ({
    erroMsg: null,
    erroMsg2: null,
    branchName: null,
    countDown: null,
    voucherDetailInfo: [],
    voucherDetailObj: [],
    VoucherUseType: Voucher_Use_Type
  }),
  mounted() {
    this.initialGetVoucher();
  },
  computed: {
    getUserVoucherComplete() {
      return this.$store.state.userVoucher.userVoucher.complete;
    },
    updateCheckUserVoucherComplete() {
      return this.$store.state.userVoucher.checkUserVoucher.complete;
    }
  },
  watch: {
    getUserVoucherComplete() {
      let response = this.$store.state.userVoucher.userVoucher;
      this.inportUserVoucher(response);
    },
    updateCheckUserVoucherComplete() {
      let response = this.$store.state.userVoucher.checkUserVoucher;
      this.inportCheckUserVoucher(response);
    }
  },
  methods: {
    onKeyPinComplete(value) {
      if (this.$route.query.branch_name == '') {
        if (this.branchName == null || this.branchName == '') {
          this.erroMsg = 'This field is required.';
          this.$refs.code.values = ['', '', '', ''];
        } else {
          this.erroMsg = '';
        }
      } else {
        if (this.$route.query.branch_name) {
          this.branchName = this.$route.query.branch_name;
        } else {
          this.$route.query.branch_name = this.branchName;
        }
      }

      if (this.$refs.code.values.includes('')) {
        this.erroMsg2 = 'This field is required.';
      } else {
        this.erroMsg2 = '';
      }

      if (
        this.branchName != null &&
        this.branchName != '' &&
        !this.$refs.code.values.includes('')
      ) {
        this.updateCheckUserVoucher(value);
      }
    },
    countDownTimer() {
      // Get today's date and time
      var now = new Date().getTime();
      // Set the date we're counting down to
      var countDownDate = new Date(
        this.voucherDetailObj.countdownTime
      ).getTime();
      setTimeout(() => {
        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        // Time calculations for days, hours, minutes and seconds
        // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        // var hours = Math.floor(
        //   (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        // );
        // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"

        // If the count down is over, write some text
        if (distance < 0) {
          this.initialGetVoucher();
        } else {
          // Output the result in an element with id="demo"
          this.countDown = seconds;
          this.countDownTimer();
        }
      }, 1000);
    },
    inportUserVoucher(response) {
      this.voucherDetailObj = response.data;
      if (this.voucherDetailObj.countdownTime) {
        this.countDownTimer();
      }
      if (this.voucherDetailObj.voucherUseType == Voucher_Use_Type.REFERRAL) {
        this.voucherDetailInfo = [
          {
            key: 'merchantName',
            name: 'merchant'
          },
          { key: 'voucherCode', name: 'voucherNumber' },
          {
            key: 'value',
            name: 'discountValue',
            class:
              this.voucherDetailObj.status !== 2
                ? 'red--text font-weight-bold'
                : 'grey--text font-weight-bold'
          }
        ];
      } else {
        this.voucherDetailInfo = [
          {
            key: 'merchantName',
            name: 'merchant'
          },
          { key: 'billAmount', name: 'billAmount' },
          { key: 'voucherCode', name: 'voucherNumber' },
          {
            key: 'value',
            name: 'discountValue',
            class:
              this.voucherDetailObj.status !== 2
                ? 'red--text font-weight-bold'
                : 'grey--text font-weight-bold'
          },
          {
            key: 'receivedPayment',
            name: 'receivedPayment',
            class: 'grey--text font-weight-bold',
            isHidden: this.voucherDetailObj.status == 2
          }
        ];
      }
      this.initialGetVoucherDetailState();
    },
    inportCheckUserVoucher(response) {
      let type;
      if (response.code == 0) {
        type = 'success';
      } else {
        type = 'error';
      }
      let title = response.message;
      let description = '';
      let buttons = [
        {
          color: 'secondary',
          text: i18nHelper.getMessage('label.ok'),
          action: () => {
            this.closeAppDialogInfo();
            this.initialGetVoucher();
            if (response.code == 0) {
              // location.reload();
            }
          }
        }
      ];
      this.$refs.code.values = ['', '', '', ''];
      this.initialUpdateCheckUserVoucherState();
      this.openAppDialogInfo(type, title, description, buttons);
    },
    initialGetVoucher() {
      this.getVoucherDetail();
    },
    getVoucherDetail() {
      let data = {
        userId: this.$route.query.user_id,
        voucherCode: this.$route.query.voucher_code,
        voucherId: this.$route.query.voucher_id,
        branchName: this.$route.query.branch_name,
        branchId: this.$route.query.branch_id,
        merchantId: this.$route.query.merchant_id
      };
      this.$store.dispatch(USER_VOUCHER_GET_USER_VOUCHER, { data });
    },
    updateCheckUserVoucher(value) {
      let data = {
        voucherCode: this.$route.query.voucher_code,
        pinVerification: value.toUpperCase(),
        branchName: this.$route.query.branch_name,
        branchId: this.$route.query.branch_id,
        merchantId: this.$route.query.merchant_id
      };

      if (value.length == 4) {
        this.$store.dispatch(USER_VOUCHER_UPDATE_CHECK_USER_VOUCHER, {
          data
        });
      }
    },
    initialGetVoucherDetailState() {
      this.$store.dispatch(USER_VOUCHER_INITIAL_GET_USER_VOUCHER_STATE);
    },
    initialUpdateCheckUserVoucherState() {
      this.$store.dispatch(
        USER_VOUCHER_INITIAL_UPDATE_CHECK_USER_VOUCHER_STATE
      );
    }
  }
};
</script>
<style lang="scss">
.voucher-redeem {
  .rounded {
    border-radius: 20px !important;
  }

  .inputPin {
    width: 275px !important;

    .react-code-input {
      display: flex;
      justify-content: space-between;

      input {
        font-size: 25px;
        color: #000;
        background: #fff;
        border-radius: 10px;
        text-transform: uppercase;
      }
    }
  }

  .branchName {
    font-size: 15px;
    color: #000;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
    text-align: center;
  }

  .infoborder {
    width: 100%;
    margin: auto;
    border-bottom: 2px dashed #fff;
  }
}
</style>
